<template>
  <v-container
    fluid
    class="visit-listing white-background main-listing-page pb-0"
  >
    <div>
      <v-tabs
        active-class="custom-tab-active"
        v-model="visitTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent tab-sticky"
      >
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#all"
        >
          <template v-if="count_all > 0">
            <span>All</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_all"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>All</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#new"
        >
          <template v-if="count_new > 0">
            <span>New</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_new"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>New</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#inprogress"
        >
          <template v-if="count_inprogress > 0">
            <span>In Progress</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_inprogress"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>In Progress</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <!--  <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#unassigned"
        >
          <template>Unassigned</template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#assigned"
        >
          <template>Assigned</template>
        </v-tab> -->

        <!--  <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#assigned"
        >
          <template>Partial Delivered</template>
        </v-tab> -->
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#partial-delivered"
        >
          <template v-if="count_pd > 0">
            <span>Partial Delivered</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_pd"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>Partial Delivered</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#delivered"
        >
          <template v-if="count_delvered > 0">
            <span>Delivered</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_delvered"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>Delivered</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#rejected"
        >
          <template v-if="count_failed > 0">
            <span>Failed</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_failed"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>Failed</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1 && false"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#overdue"
        >
          <template v-if="count_overdue > 0">
            <span>Overdue</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_overdue"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span> Overdue</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#today"
        >
          <template v-if="count_today > 0">
            <span>Today</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_today"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>Today</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#tomorrow"
        >
          <template v-if="count_tomorrow > 0">
            <span>Tomorrow</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_tomorrow"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>Tomorrow</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#this-week"
        >
          <template v-if="count_this_week > 0">
            <span>This Week</span>
            <v-badge
              style="margin-left: 5px; margin-top: 9px"
              bordered
              color="orange darken-4"
              :content="count_this_week"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>This Week</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge
          ></template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#upcomming"
        >
          <template v-if="count_upcomming > 0">
            <span>Upcoming</span>
            <v-badge
              bordered
              color="orange darken-4"
              :content="count_upcomming"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
          <template v-else>
            <span>Upcoming</span>
            <v-badge
              bordered
              color="orange darken-4"
              content="0"
              style="margin-left: 5px; margin-top: 9px"
            >
            </v-badge>
          </template>
        </v-tab>
        <v-tabs-items v-model="visitTab">
          <v-tab-item value="all">
            <VisitListingTemplate
              :is-admin="isAdmin"
              :visit-status="0"
              visitType="all"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'delivery.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="new">
            <VisitListingTemplate
              :is-admin="isAdmin"
              :visit-status="1"
              visitType="all"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'delivery.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="inprogress">
            <VisitListingTemplate
              :is-admin="isAdmin"
              :visit-status="8"
              visitType="all"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'delivery.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="unassigned">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="all"
              :visit-status="5"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'delivery.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="assigned">
            <VisitListingTemplate
              :is-admin="isAdmin"
              :visit-status="6"
              visitType="all"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'delivery.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="partial-delivered">
            <VisitListingTemplate
              :is-admin="isAdmin"
              :visit-status="3"
              visitType="all"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'delivery.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="delivered">
            <VisitListingTemplate
              :is-admin="isAdmin"
              :visit-status="7"
              visitType="all"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'delivery.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="rejected">
            <VisitListingTemplate
              :is-admin="isAdmin"
              :visit-status="2"
              visitType="all"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'delivery.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="overdue">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="overdue"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="today">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="today"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="tomorrow">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="tomorrow"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="this-week">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="this-week"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item value="upcomming">
            <VisitListingTemplate
              :is-admin="isAdmin"
              visitType="upcomming"
              :detail-route="`${
                follow_up_route == 1 ? 'follow-up.detail' : 'visit.detail'
              }`"
            ></VisitListingTemplate>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VisitListingTemplate from "@/view/pages/delivery/Visit-Listing-Template";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "visit",
  mounted() {
    const route_name = this.$route.name;
    this.follow_up_route = this.$route.query.follow_up;
    if (this.follow_up_route && this.follow_up_route == 1) {
      this.title = "Follow Up";
    } else {
      this.title = "Delivery";
    }
    const indexd = route_name.indexOf("admin");
    if (indexd >= 0) {
      this.isAdmin = true;
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
    //this.visitTab = "follow_up_open";
    this.getDeliveryCounts();
  },
  data() {
    return {
      title: null,
      isAdmin: false,
      pageLoading: false,
      follow_up_route: null,
      // visitTab: null,
      count_all: 0,
      count_overdue: 0,
      count_today: 0,
      count_tomorrow: 0,
      count_this_week: 0,
      count_completed: 0,
      count_upcomming: 0,
      count_failed: 0,
      count_delvered: 0,
      count_pd: 0,
      count_inprogress: 0,
      count_new: 0,
      counts: {
        all: 0,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        this_week: 0,
        upcomming: 0,
        completed: 0,
        follow_up: 0,
      },
    };
  },
  methods: {
    getDeliveryCounts() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "delivery/counts",
          data: {
            module_type: "delivery",
          },
        })
        .then(({ data }) => {
          _this.count_all = data.count_all;
          _this.count_overdue = data.count_overdue;
          _this.count_today = data.count_today;
          _this.count_tomorrow = data.count_tomorrow;
          _this.count_this_week = data.count_this_week;
          _this.count_completed = data.count_completed;
          _this.count_upcomming = data.count_upcomming;
          _this.count_failed = data.count_failed;
          _this.count_delvered = data.count_delvered;
          _this.count_pd = data.count_pd;
          _this.count_inprogress = data.count_inprogress;
          _this.count_new = data.count_new;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  components: {
    VisitListingTemplate,
  },
  created() {
    if (this.$route.query && this.$route.query.tab) {
      this.visitTab = this.$route.query.tab;
    }
  },
  computed: {
    visitTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.visitTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "follow_up_open";
      },
    },
  },
};
</script>
